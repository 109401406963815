@use 'variables';

// themes - our custom or/and out of the box themes
@use 'themes';

// Uncomment the following to customize the Kendo Bootstrap Theme
// And remove the @progress/kendo-theme-bootstrap/dist/all.css from angular.json
@use '@progress/kendo-theme-bootstrap/dist/all' as bs with (
  $kendo-slider-alt-size: 48px,
  $kendo-pager-sm-dropdown-width: 7em,
  $kendo-pager-md-dropdown-width: 7em,
  $kendo-pager-lg-dropdown-width: 8em
);

@use '../kendo/switch';
@use 'kendo-custom-buttons';
// framework component styles
@use '@nebular/theme/styles/globals' as nb_theme_globals;
@use '@nebular/auth/styles/globals' as nb_auth_globals;

// ...
// global app font size
@use './font-size';
// loading progress bar theme
@use './pace.theme';
//@use './bootstrap-rtl';

@use './fa-radio-checkboxes';

@use './layout';
@use './overrides';

@use './animated-ellipses.css';

// Un-hide components (v5 only issue)
// This fix isn't needed once upgrading Kendo UI theme to v6
.k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: inline;
}

//////////////////////////////////////////////////
// Include all of BS (not recommended)
//
// DY 2022-06-18:
//   For now, @import must be used with BS5
//   https://stackoverflow.com/q/68586205/2557554
//   @use can only be done for the whole of BS5; on a per-module basis it must be @import for now
//$info: #0dcaf0; // original #0dcaf0
//$warning: #ffc107; // original #ffc107
@import 'bootstrap/scss/bootstrap';

////////////////////////////////////////////////////
//// Include only what's needed from BS
//@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';
//@import '~bootstrap/scss/mixins';
//
//// Layout & components
//@import '~bootstrap/scss/root';
//@import '~bootstrap/scss/reboot';
//@import '~bootstrap/scss/type';
//@import '~bootstrap/scss/images';
//@import '~bootstrap/scss/containers';
//@import '~bootstrap/scss/grid';
//@import '~bootstrap/scss/tables';
//@import '~bootstrap/scss/forms';
//@import '~bootstrap/scss/buttons';
////@import '~bootstrap/scss/transitions';
//@import '~bootstrap/scss/dropdown';
//@import '~bootstrap/scss/button-group';
//@import '~bootstrap/scss/nav';
//@import '~bootstrap/scss/navbar';
//@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/accordion';
////@import '~bootstrap/scss/breadcrumb';
////@import '~bootstrap/scss/pagination';
//@import '~bootstrap/scss/badge';
//@import '~bootstrap/scss/alert';
////@import '~bootstrap/scss/progress';
////@import '~bootstrap/scss/list-group';
////@import '~bootstrap/scss/close';
////@import '~bootstrap/scss/toasts';
////@import '~bootstrap/scss/modal';
////@import '~bootstrap/scss/tooltip';
////@import '~bootstrap/scss/popover';
////@import '~bootstrap/scss/carousel';
////@import '~bootstrap/scss/spinners';
////@import '~bootstrap/scss/offcanvas';
////@import '~bootstrap/scss/placeholders';
//
//// Helpers
////@import '~bootstrap/scss/helpers';
//
//// Utilities
////@import '~bootstrap/scss/utilities/api';

// End of Bootstrap imports
//////////////////////////////////////////////////

// install the framework and custom global styles
@include themes.nb-install() {

  // framework global styles
  @include nb_theme_globals.nb-theme-global();
  @include nb_auth_globals.nb-auth-global();
  @include kendo-custom-buttons.kendo-custom-buttons();

  @include layout.ngx-layout();
  //// loading progress bar
  @include pace.ngx-pace-theme();

  @include fa-radio-checkboxes.ngx-radio-checkboxes-theme();

  @include overrides.nb-overrides();

  .btn-placeholder-lg {
    // TODO: Find an alternative
    //@include b-btn-size(large);
  }

  h1 {
    margin: 0.33em 0;
  }

  .bg-success-lite {
    background-color: themes.nb-theme(color-success-200);
  }
  .bg-warning-lite {
    background-color: themes.nb-theme(color-warning-200);
  }
  .bg-danger-lite {
    background-color: themes.nb-theme(color-danger-200);
  }

  .k-state-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
  }
  .k-state-error {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
  }
  .k-state-info {
    background-color: #d1ecf1;
    color: #0c5460;
    border-color: #bee5eb;
  }
  .k-state-warning {
    background-color: #fff3cd;
    color: #856404;
    border-color: #ffeeba;
  }
}

//.k-list-container {
//  .k-list-optionlabel {
//    color: nb-theme(list-item-text-color);
//    font-family: nb-theme(list-item-font-family);
//    font-size: nb-theme(list-item-font-size);
//    font-weight: nb-theme(list-item-font-weight);
//    line-height: nb-theme(list-item-line-height);
//  }
//}
//
//.k-grid-header .k-header {
//  height: auto;
//  white-space: normal;
//}

.nb-theme-corporate {
  z-index: variables.$z-index-overlay;
}

.focus-mode {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: variables.$z-index-focus-mode;
}

@media print {
  body * {
    visibility: hidden;
  }

  ngx-back {
    display: none !important;
  }

  .main-content, .main-content * {
    visibility: visible;
  }
  .main-content {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.menu-icon.fas {
  font-size: 2em !important;
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

.form-group.required .control-label::after {
  content: ' *';
  color: red;
}

.page-template {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.page-template .header {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  border-bottom: 1px solid #888;
  color: #888;
}

.page-template .footer {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  border-top: 1px solid #888;
  text-align: center;
  color: #888;
}

//.k-widget.form-control {
//  //padding: 0;
//
//  &.k-datepicker, &.k-timepicker, &.k-combobox, &.k-maskedtextbox,
//  &.k-switch, &.k-dropdown, &.k-numerictextbox {
//
//    .k-textbox, .k-dropdown-wrap, .k-picker-wrap, .k-dateinput-wrap {
//      height: 100%;
//      border: 0;
//
//      input {
//        height: 100%;
//      }
//    }
//  }
//}

input, .k-widget {

  &.ng-invalid {
    border-color: lightcoral;
  }

  //&.ng-valid, &.ng-valid.required {
  //  border: 2px solid lightgreen !important;
  //}
}

select, .k-widget {

  &.ng-invalid {
    border-color: lightcoral;
  }

  //&.ng-valid, &.ng-valid.required {
  //  border: 2px solid lightgreen !important;
  //}
}

.viewport {
  position: relative;
}

.viewport > canvas {
  position: inherit;
  max-width: 100% !important;
  z-index: 99;
}

.viewport > video {
  max-width: 100% !important;
}

.h-33 {
  height: 33% !important;
}

.h-10 {
  height: 10% !important;
}

.simple-keyboard {
  max-width: 300px;
}

.disabled {
  background-color: #cfcfcf !important;
}

.k-checkbox-label, .k-radio-label {
  line-height: 1.1em !important;
}

// From https://github.com/twbs/bootstrap/issues/22104#issuecomment-387431795
@media (max-width: 575.98px) {
  // Manage Display Headers for Mobile Devices
  .display-1 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1;
  }

  .display-2 {
    font-size: 2.75rem;
    font-weight: 300;
    line-height: 1;
  }

  .display-3 {
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 1;
  }

  .display-4 {
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1;
  }
}

.dialog-card {
  max-width: 95vw;
  max-height: 95vh;
}

kendo-popup {
  z-index: variables.$z-index-popup !important; // without this, the sidebar overlays the popups
}

html, body {
  height: 100%;

  // Disable pull-to-refresh behaviour on mobile
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: Roboto,
  'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', // fallbacks
  sans-serif;
}

// Used for actions buttons below tables, typically
.btn-action {
  width: 250px;
}

.toastr-overlay-container {
  z-index: 600;
}

nb-toastr-container {
  margin-top: 5rem;
}

.k-pager-md .k-pager-info,
.k-pager-sm .k-pager-info,
.k-pager-sm .k-pager-sizes,
.k-pager-sm .k-pager-numbers {
  display: inline;
}

.floating-status {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
